//
// user.scss
// Use this to write your custom SCSS
//

// Set different hero background based on theme (dark/light) preference

// Hero
#hero {
  background-color: $gray-300;
  color: $gray-700;
}

// Navbar
#nav {
  background-color: $gray-100;
}

#nav .nav-item a:hover {
  color: $link;
}

// Hide spinner on Chrome browser
video::-webkit-media-controls {
  visibility: hidden;
}

video::-webkit-media-controls-enclosure {
  visibility: visible;
}

// Update colors for dark theme
@media (prefers-color-scheme: dark) {
  // Navbar
  #nav {
    background-color: #23282d;
  }

  // Hero
  #hero {
    background-color: #18191c;
    color: $gray-400;
  }
}
