//
// Code
// --------------------------------------------------

:not(pre) > code[class*='language-'],
pre[class*='language-'],
pre {
  --#{$prefix}pre-border-width: #{$pre-border-width};
  --#{$prefix}pre-border-color: #{$pre-border-color};
  --#{$prefix}pre-bg: #{$pre-bg};
  --#{$prefix}pre-font-size: #{$code-font-size};
  --#{$prefix}pre-line-numbers-border-width: #{$pre-line-numbers-border-width};
  --#{$prefix}pre-line-numbers-border-color: #{$pre-line-numbers-border-color};
  --#{$prefix}pre-line-numbers-color: #{$pre-line-numbers-color};
  --#{$prefix}pre-tag-color: #4e54c8;
  --#{$prefix}pre-comment-color: #{darken($gray-500, 6%)};
  --#{$prefix}pre-attr-name-color: #4091e3;
  --#{$prefix}pre-attr-value-color: #e3116c;
  --#{$prefix}pre-class-name-color: #36acaa;

  max-width: 100%;
  margin: 0;
  padding: $pre-padding-y $pre-padding-x;
  background-color: var(--#{$prefix}pre-bg);
  border: var(--#{$prefix}pre-border-width) solid
    var(--#{$prefix}pre-border-color);
  @include border-radius($pre-border-radius);
  font-size: var(--#{$prefix}pre-font-size);
  @include box-shadow($pre-box-shadow);
}
.code-toolbar {
  :not(pre) > code[class*='language-'],
  pre[class*='language-'] {
    padding-top: $spacer * 2.25;
  }
}
code[class*='language-'],
pre[class*='language-'],
pre {
  --#{$prefix}pre-color: #{$pre-color};

  color: var(--#{$prefix}pre-color);
  text-shadow: none;
}

// Line numbers

.line-numbers .line-numbers-rows {
  border-right: var(--#{$prefix}pre-line-numbers-border-width) solid
    var(--#{$prefix}pre-line-numbers-border-color);
  > span::before {
    color: var(--#{$prefix}pre-line-numbers-color);
  }
}

// Toolbar (Copy button)

div.code-toolbar > .toolbar {
  opacity: 1;
  > .toolbar-item > a,
  > .toolbar-item > button,
  .toolbar-item > span {
    display: inline-block;
    margin: 0.375rem 0.5rem;
    padding: 0.25rem 0.75rem;
    transition: $btn-transition;
    border-radius: $border-radius-sm;
    border: $border-width solid rgba($primary, 0.35);
    background-color: transparent;
    color: $primary !important;
    font: {
      size: $font-size-xs;
      weight: $font-weight-medium;
    }
    box-shadow: none;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &:hover {
      background-color: $primary;
      color: $white !important;
    }
  }
}

// Code highlighting colors overrides (prism.js)

.token.boolean,
.token.constant,
.token.deleted,
.token.number,
.token.property,
.token.symbol,
.token.tag {
  color: var(--#{$prefix}pre-tag-color);
}
.token.cdata,
.token.comment,
.token.doctype,
.token.prolog {
  color: var(--#{$prefix}pre-comment-color);
}
.token.attr-name,
.token.builtin,
.token.char,
.token.inserted,
.token.selector {
  color: var(--#{$prefix}pre-attr-name-color);
}
.token.atrule,
.token.attr-value,
.token.keyword,
.token.string {
  color: var(--#{$prefix}pre-attr-value-color);
}
.language-css .token.string,
.style .token.string,
.token.entity,
.token.operator,
.token.url,
.token.class-name,
.token.function {
  color: var(--#{$prefix}pre-class-name-color);
  background: none;
}
